<template>
  <Popup @close="redirect">
    <div class="row mt-4 justify-content-center text-center">
      <div class="col-7 col-md-6 text-center">
        <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
        <TickAnimation v-else />
      </div>
      <div class="col-12 mb-3 text-center medium font23 green-text">
        Alhamdulillah!<br/>Your Recurring Donation has started!
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    TickAnimation: defineAsyncComponent(() => import('@/components/animations/TickAnimation.vue'))
  },
  name: 'Recurring donation Complete Popup',
  mounted () {
    this.startTime()
  },
  computed: {
    ...mapGetters([
      'user', 'isApp'
    ])
  },
  methods: {
    startTime () {
      setTimeout(() => {
        this.redirect()
      }, 12000)
    },
    redirect () {
      if (this.user) {
        this.$router.push('/managerecurringdonations')
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>
